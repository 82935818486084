
.color-pair-white {
  background-color: white !important;
  color: black !important;
}

.color-pair-black {
  background-color: black !important;
  color: white !important;
}

.color-pair-gray {
  background-color: #EBEBEB !important;
}

.color-pair-blue {
  background-color: #2C71F0 !important;
  color: #FFFFFF !important;
}

.color-pair-green-white {
  background-color: #58F472 !important;
  color: white !important;
}

.color-pair-green {
  background-color: #58F472 !important;
  color: black !important;
}

.color-pair-medium-green {
  background-color: #8DE877 !important;
  color: #154808 !important;
}

.color-pair-light-blue {
  background-color: #2C71F026 !important;
  color: #2C71F0 !important;
}

.color-pair-light-green {
  background-color: #DFF3EB !important;
  color: #2F771E !important;
}
.color-pair-light-blue {
  background-color: #2C71F026 !important;
  color: #2C71F0 !important;
}

.color-pair-light-red {
  background-color: #FB2A2AA2 !important;
  color: #620303 !important;
}

.color-pair-white {
  background-color: white !important;
  color: black !important;
}
