// define px sizes as standards 2, 4, 8, 12, 16, 24, 32, 40, 48, 56, 64, 72, 80
// https://zellwk.com/blog/why-vertical-rhythms/
$standardSizesMap: (
  2: 2px,
  4: 4px,
  8: 8px,
  12: 12px,
  14: 14px,
  16: 16px,
  20: 20px,
  24: 24px,
  32: 32px,
  40: 40px,
  48: 48px,
  56: 56px,
  64: 64px,
  72: 72px,
  80: 80px,
);

@function standardSizes($key) {
  @return map-get($standardSizesMap, $key);
}

@each $key,
$value in $standardSizesMap {
  .margin-y-#{$key} {
    margin-top: $value;
    margin-bottom: $value;
  }

  .margin-x-#{$key} {
    margin-left: $value;
    margin-right: $value;
  }
}